<template>
  <div>
    <h2>SVG test</h2>

    <SvgTestComponent />

  </div>
</template>

<script>
// @ is an alias to /src
import SvgTestComponent from '@/components/svg/Test.vue';

export default {
  name: 'SvgTest',
  components: {
    SvgTestComponent,
  },

  data() {
    return {
    };
  },

  methods: {
  },
};
</script>
