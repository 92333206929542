<template>
  <div class="flex">
    <!-- <svg class="base" width="100%" version="1.1" viewBox="0 0 210 67.019" xmlns="http://www.w3.org/2000/svg">
    <g>
      <g transform="matrix(10.268 0 0 10.268 3358 -2810.9)">
        <path fill="#00fcfc" d="m-326.78 273.76h19.944c.14053 0 .2537.1132.2537.25397v6.0191c0 .1408-.1132.25399-.2537.25399h-19.944c-.14081 0-.25398-.1132-.25398-.25399v-6.0191c0-.14079.1132-.25397.25398-.25397z"/>
        <g fill="#404040">
          <path d="m-322.08 276.97c-.0159-.40425-.15759-.73621-.42537-.99857-.26752-.26238-.58376-.39342-.95498-.39342-.39883 0-.73188.13646-1.0048.40939-.27293.27266-.40397.61761-.40397 1.0376 0 .42619.131.76978.40397 1.0376.26752.27293.60083.40397.99316.40397.30569 0 .5732-.0709.80281-.21363.2296-.14187.39883-.34902.51823-.62735l-.64413-.1362c-.14162.2399-.37122.35958-.67691.35958-.16923 0-.31625-.0542-.44217-.16923-.12595-.11426-.20712-.26671-.23988-.46328h2.074zm-1.3804-.76383c.15243 0 .28349.0433.40289.12476.11533.0877.19685.21363.24126.37771h-1.3156c.0379-.15866.11944-.28456.25045-.37122.13127-.0879.2678-.13126.42104-.13126z"/>
          <path d="m-321.44 275.66v1.7196c0 .36608.0931.63791.28456.81879.1852.17978.4473.26751.78575.26751.21798 0 .45922-.0655.70994-.20199l-.2074-.60082c-.16408.0866-.31625.131-.4538.131-.26642 0-.39775-.15326-.39775-.46951v-1.6646z"/>
          <path d="m-315.85 275.58c-.42619 0-.753.15324-.99424.44729-.20741-.29405-.50119-.44729-.89487-.44729-.33846 0-.61112.10363-.81311.30569v-.22311h-.72158v2.7233h.72158v-1.3105c0-.27808.0538-.48548.16382-.62709.10881-.13646.26129-.20741.45786-.20741.15352 0 .27942.0542.38286.16409.0975.10881.15243.25072.15243.42509v1.5558h.72024v-1.3105c0-.27266.055-.48006.1703-.62194.10882-.14161.26643-.21256.46328-.21256.15326 0 .27916.0542.38286.16409.0983.10881.15243.25072.15243.42509v1.5558h.71482v-1.7028c0-.32247-.10377-.58484-.31083-.79225-.20742-.20713-.45813-.31083-.74785-.31083z"/>
          <path d="m-312.52 275.58c-.35524 0-.63873.10881-.8567.32789v-.24531h-.72131v2.7233h.72131v-1.2057c0-.62302.22312-.93928.67692-.93928.163 0 .30054.0593.39774.17465.0983.11944.15326.27293.15326.46436v1.506h.72022v-1.6595c0-.33845-.10337-.61138-.30027-.82393-.20199-.21363-.46328-.32247-.79117-.32247z"/>
          <path d="m-311.12 275.66v.5889h.58484v1.1307c0 .36608.0931.63791.28349.81879.18629.17978.44866.26751.78712.26751.21768 0 .45786-.0655.70966-.20199l-.2074-.60082c-.16381.0866-.31733.131-.45379.131-.26752 0-.39857-.15326-.39857-.46951v-1.0757h.89053v-.5889z"/>
        </g>
      </g>
    </g>
    </svg>-->
    <!-- <div v-for="i in 5" :key="i"> -->
    <svg
      ref="mainSvg"
      class="base"
      width="100%"
      height="100%"
      version="1.1"
      :viewBox="viewBoxStr"
      xmlns="http://www.w3.org/2000/svg"
    >

      <!-- Connections -->
      <path
        v-for="(connection, idx) of connections"
        :key="idx"
        class="connector"
        :d="connection"
        pathLength="1"
      />

      <!-- Nodes -->
      <g v-for="node of nodes" :key="node.id" @mousedown="move(node.id, $event)">
        <rect :x="node.x" :y="node.y" :width="node.w" :height="node.h" rx="15" pathLength="1" />

        <!-- Node Name -->
        <text :x="node.x + 15" :y="node.y + 22" class="node-name">
          {{ node.name }}
        </text>

        <line :x1="node.x" :y1="node.y + 32" :x2="node.x + node.w" :y2="node.y + 32" stroke="rgba(0,0,0,0.3)" />

        <!-- Inputs -->
        <circle
          v-for="(input, idx) of node.inputs"
          :key="input.id"
          :r="socketSize * 0.5"
          :cx="node.x"
          :cy="node.y + 45 + ((socketSize + socketSize * 0.5) * idx)"
        />

        <!-- Outputs -->
        <circle
          v-for="(output, idx) of node.outputs"
          :key="output.id"
          :r="socketSize * 0.5"
          :cx="node.x + node.w"
          :cy="node.y + node.h - 15 - ((socketSize + socketSize * 0.5) * idx)"
        />
      </g>
    </svg>
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: 'MySVGComponent',
  data() {
    return {
      scale: 1.0,
      socketSize: 10,
      viewBox: {
        x1: 0,
        y1: 0,
        x2: 1000,
        y2: 500,
      },
      nodes: [
        {
          id: 'r1',
          name: 'Node 1',
          x: 10,
          y: 10,
          w: 150,
          h: 200,
          inputs: [],
          outputs: [
            {
              id: 'o1',
              name: 'Output 1',
              connections: [
                {
                  node: 'r2',
                  input: 'i1',
                },
                {
                  node: 'r3',
                  input: 'i1',
                },
                {
                  node: 'r3',
                  input: 'i2',
                },
              ],
            },
          ],
        },
        {
          id: 'r2',
          name: 'Node 2',
          x: 300,
          y: 300,
          w: 150,
          h: 100,
          inputs: [
            {
              id: 'i1',
              name: 'Input 1',
            },
            {
              id: 'i2',
              name: 'Input 2',
            },
          ],
          outputs: [
            {
              id: 'o1',
              name: 'Output 1',
              connections: [
                {
                  node: 'r3',
                  input: 'i3',
                },
              ],
            },
          ],
        },
        {
          id: 'r3',
          name: 'Node 3',
          x: 500,
          y: 100,
          w: 200,
          h: 200,
          inputs: [
            {
              id: 'i1',
              name: 'Input 1',
            },
            {
              id: 'i2',
              name: 'Input 2',
            },
            {
              id: 'i3',
              name: 'Input 3',
            },
          ],
          outputs: [
            {
              id: 'o1',
              name: 'Output 1',
              connections: [],
            },
            {
              id: 'o2',
              name: 'Output 2',
              connections: [],
            },
          ],
        },
      ],
    };
  },

  computed: {
    viewBoxStr() {
      const str = Object.values(this.viewBox).join(' ');
      // console.log('str', str);
      return str;
    },

    viewBoxWidth() {
      return this.viewBox.x2 - this.viewBox.x1;
    },

    nodesById() {
      const nodesById = this.nodes.reduce((obj, item) => ({
        ...obj,
        [item.id]: item,
      }), {});

      // console.log('Nodes by id', nodesById);

      return nodesById;
    },

    connections() {
      const c = [];
      this.nodes.forEach((node) => {
        if (node.outputs.length) {
          node.outputs.forEach((output, outputIdx) => {
            if (output.connections.length) {
              output.connections.forEach((connection) => {
                const nodeTo = this.nodesById[connection.node];
                const inputIdx = nodeTo.inputs.findIndex((input) => input.id === connection.input);
                const input = nodeTo.inputs[inputIdx];

                const conn = {
                  from: {
                    node: node.id,
                    outputIdx,
                    output: output.id,
                  },
                  to: {
                    node: nodeTo.id, // == connection.node (but clearer)
                    inputIdx,
                    input: input.id,
                  },
                };

                const d = this.d(conn);

                c.push(d);
              });
            }
          });
        }
      });

      // console.log('connections', c);

      return c;
    },
  },

  methods: {
    calculateScale() {
      const rootRect = this.$refs.mainSvg.getBoundingClientRect();
      this.viewBox = {
        x1: 0,
        y1: 0,
        x2: rootRect.width,
        y2: rootRect.height,
      };
      this.scale = this.viewBoxWidth / rootRect.width;
      console.log(this.scale);
    },

    d(connection) {
      const n1 = this.nodesById[connection.from.node];
      const n1OutputIdx = connection.from.outputIdx;
      const n2 = this.nodesById[connection.to.node];
      const n2InputIdx = connection.to.inputIdx;
      const p1x = n1.x + n1.w;
      // node.y + node.h - 15 - ((socketSize + socketSize * 0.5) * idx)
      const p1y = n1.y + n1.h - 15 - (this.socketSize + this.socketSize * 0.5) * n1OutputIdx;
      const p2x = n2.x;
      // node.y + 15 + ((socketSize + socketSize * 0.5) * idx)
      const p2y = n2.y + 45 + (this.socketSize + this.socketSize * 0.5) * n2InputIdx;
      const a = p1x - p2x;
      const b = p1y - p2y;
      const offset = Math.sqrt(a * a + b * b) * 0.55;
      const d = `M${p1x} ${p1y} C ${p1x + offset} ${p1y} ${p2x - offset} ${p2y} ${p2x} ${p2y}`;

      return d;
    },

    move(nodeId, evt) {
      let mouseX = evt.clientX;
      let mouseY = evt.clientY;

      const handleMove = (e) => {
        // console.log('e', e);
        const dx = (mouseX - e.clientX);
        const dy = (mouseY - e.clientY);
        mouseX = e.clientX;
        mouseY = e.clientY;
        this.nodesById[nodeId].x -= dx * this.scale;
        this.nodesById[nodeId].y -= dy * this.scale;
      };

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
      document.addEventListener('mousemove', handleMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
  },

  mounted() {
    this.calculateScale();
    window.addEventListener('resize', this.calculateScale);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateScale);
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  gap: 20px;
}
.base {
  padding: 0;
  margin: 0;
  background: #eee;
}

.connector {
  fill: none;
  stroke: rgb(223, 34, 182);
  stroke-width: 4;
  /* stroke-dasharray: 0.1;
  stroke-dashoffset: 1;
  animation: dash 2s linear infinite; */
}

rect,
circle {
  fill: #cccccc;
  stroke: #666;
  stroke-width: 2;
}

.node-name {
  font-size: 15px;
  font-weight: bold;
}

@keyframes dash {
  from {
    stroke-dashoffset: 1;
  }
  to {
    stroke-dashoffset: 0;
  }
}
</style>
